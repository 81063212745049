if (typeof init === 'undefined') {
  const init = function () {
    console.log('hello');
    const root = document.createElement('div');
    root.id = 'root';

    document.body.appendChild(root);

    // const reactScript = document.createElement('script')
    // root.appendChild(reactScript)

    console.log('hello world!', document);
  };
  init();
}

export {};
